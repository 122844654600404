/* eslint-disable import/prefer-default-export */
import { optionsAutomation } from '@modules/theme/helpers/menuAutomation';
import { optionsCategory } from '@modules/theme/helpers/menuCategory';
import { optionsClient } from '@modules/theme/helpers/menuClient';
import { optionsApiLog } from '@modules/theme/helpers/menuApiLog';

export const helpersMenuList = (t) => [
    ...optionsAutomation(t),
    ...optionsCategory(t),
    ...optionsClient(t),
    ...optionsApiLog(t),
];
